import React, { useEffect, useRef, useState } from 'react'
import AddGradeModal from './components/AddUserGroupModal'
import { Button, Card, Col, Row, Spinner, Table } from 'react-bootstrap'
import CsLineIcons from 'cs-line-icons/CsLineIcons'
import classNames from 'classnames'
import { callApi } from 'utils/callApi'
import CustomPagination from "./components/pagination"
import PageSize from './components/PageSize'
import { ButtonGroup } from 'reactstrap'
import EditGardePayModal from './components/EditUserGroupModal'
import HtmlHead from 'components/html-head/HtmlHead'
import { useAsyncDebounce } from 'react-table'
import BreadcrumbList from 'components/breadcrumb-list/BreadcrumbList'
import { CloudFormation } from 'aws-sdk'



function UserGroup() {
    const [OpenAddUserGroupModal, setOpenUserGroupModal] = useState(false)
    const [editUserGroupModal, setEditUserGroupModal] = useState(false)
    const [totalPage, setTotalPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedPageSize, setSelectedPageSize] = useState(8);
    const [AllUserGroup, setAllUserGroup] = useState([])
    const [allAdminUser, setAllAdminUser] = useState([])
    const [postAndVacancy, setPostAndVacancy] = useState([])
    const [selectedUserGroup, setSelectedUserGroup] = useState([])
    const [selectAdvertisementOption, setSelectAdvertisementOption] = useState([])

    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState("")
    const wasNonEmpty = useRef(false);

    const title = 'User Group';
    const breadcrumbs = [
        { to: '', text: 'Home' },
        { to: 'masters/rolesAndUsers/userGroup', text: 'UserGroup' },
    ];
    const getAllUserGroup = async () => {
        try {
        
            let config = {
                method: 'post',
                url: 'master/userGroup/admin/getAllUserGroup',
                data: {
                    pageSize: selectedPageSize,
                    currentPage: currentPage
                }
            }
            const response = await callApi(config)
            if (response?.data?.code == 200) {
                setAllUserGroup(response?.data?.data)
                setTotalPage(Math.ceil(response.data.totalCount / selectedPageSize))


            }
        }
        catch (error) {
            console.log(error)
        }


    }
    const getAllAdminUser = async () => {
        try {
            let config = {
                method: 'post',
                url: 'master/userGroup/admin/getAllAdminUserOnly',

            }

            let response = await callApi(config)
            if (response?.data?.code == 200) {
                const tempArray = response?.data?.data?.map(item => {
                    return {
                        label: `${item?.firstName} ${item?.lastName}`,
                        value: item?._id,
                        key: item
                    }
                })
                setAllAdminUser(tempArray)

            }
        } catch (error) {
            console.log(error)
        }
    }


    const getAllAdvertisement = async () => {
        try {
            let config = {
                method: 'post',
                url: 'master/userGroup/admin/getAllAdvertisementForUserGroup',

            }
            const response = await callApi(config)
            console.log(response?.data?.data, "here is response")

            const tempArray = response?.data?.data?.map(item => {
                return {
                    label: item?.advertisementHeading,
                    value: item?._id,
                    key:item
                };
            });
            setSelectAdvertisementOption(tempArray)






        } catch (error) {
            console.log(error)
        }
    }



    const fetchData = async () => {
        setLoading(true);
        try {
            await Promise.all([
                getAllUserGroup(),
                getAllAdminUser(),
                getAllAdvertisement(),
            ]);
        } catch (error) {
            console.error("Error fetching data", error);
        } finally {
            setLoading(false);
        }
    };


    const handleSearchOrFetch = async () => {
        if (value) {
            await searchGrade(value);
        } else {
            await getAllUserGroup(); 
        }
    };

    useEffect(() => {
        fetchData(); // Initial fetch when component mounts
    }, []);
    useEffect(() => {
        handleSearchOrFetch(); // Runs on page or value change
    }, [value, selectedPageSize, currentPage]);





    const searchGrade = async (val) => {
        try {


            let config = {
                method: 'post',
                url: 'master/gradeReport/admin/searchGradeReport',
                data: {
                    searchQuery: val,
                    pageSize: selectedPageSize,
                    currentPage: currentPage

                }
            }
            const response = await callApi(config)
            if (response?.data?.code == 200) {
                setAllGrade(response?.data?.data)

                setTotalPage(Math.ceil(response.data.totalCount / selectedPageSize))


            }
        } catch (error) {
            console.log(error)
        }

    }
    const handleRefresh = () => {
        getAllUserGroup()
    }

    const onChange = useAsyncDebounce((val) => {

        if (val.trim() !== "") {

            searchGrade(val);
            wasNonEmpty.current = true
        } else if (wasNonEmpty.current) {
            searchGrade(val);
            wasNonEmpty.current = false

        }
    }, 500);
    return (
        loading ? (
            <div className='d-flex justify-content-center align-items-center' style={{ height: "50vh" }}>
                <Spinner animation="border" variant="primary" />
            </div>) : (
            <>
                <HtmlHead title={'UserGroup'} description={'UserGroup'} />
                <Col>
                    <Row>
                        <Col >
                            {/* <h1 className="mb-0 pb-0 display-4">{title}</h1>
                        <BreadcrumbList items={breadcrumbs} /> */}
                            <div className='headCardAll'>
                                <span className='topCard-design'>{title}</span>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Card className='p-3 allrole-card'>
                    <Row className='d-flex flex-wrap justify-content-between'>
                        <Col xs="12" md="3" className='search-box-allroles' >
                            <div className="search-input-container allrole-search border-primary">
                                <input
                                    className="form-control datatable-search"
                                    value={value || ''}
                                    onChange={(e) => {
                                        setValue(e.target.value);
                                        onChange(e.target.value);
                                    }}
                                    placeholder="Search..."
                                />
                                {value && value.length > 0 ? (
                                    <span
                                        className="search-delete-icon"
                                        onClick={() => {
                                            setValue('');
                                            onChange('');
                                        }}
                                    >
                                        <CsLineIcons icon="close" />
                                    </span>
                                ) : (
                                    <span className="search-magnifier-icon pe-none">
                                        <CsLineIcons icon="search" />
                                    </span>
                                )}
                            </div>
                            <Button variant='foreground-alternate' className='p-0 m-0' onClick={handleRefresh}>
                                <CsLineIcons icon="refresh-horizontal" className='' />
                            </Button>
                        </Col>
                        <Col xs="12" md="6" lg="9" className='d-flex flex-row flex-wrap justify-content-between mt-3 mt-md-0 flex-md-row justify-content-md-end text-end'>
                            <PageSize setCurrentPage={setCurrentPage} pageSize={selectedPageSize} getAllUserGroup={getAllUserGroup} setSelectedPageSize={setSelectedPageSize} />
                            <ButtonGroup>
                                <Button variant="outline-primary" className=" pt-1 pb-1 w-100 w-md-auto add-datatable ps-2 pe-2" onClick={() => { setOpenUserGroupModal(true) }}>
                                    <CsLineIcons icon="plus" />
                                </Button>
                                <Button className='ps-3 m-0' onClick={() => { setOpenUserGroupModal(true) }}>
                                    Add
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Row>

                        {
                            AllUserGroup?.length == 0 ? (
                                <p className="d-flex  text-mute text-center justify-content-center align-item-center ">
                                    {' '}
                                    <CsLineIcons icon="warning-hexagon" />
                                    &nbsp;
                                    No UserGroup Found!
                                </p>
                            ) : (
                                <Row className='mt-2'>
                                    <Col xs={12} md={12} lg={12}>
                                        <Table responsive className={`${classNames} table-responsive allrole-table`}>
                                            <thead className="text-uppercase w-10 roles-table-header">


                                                <th className='text-left text-table-heading pt-2 pb-2 pe-2'>Sl. No.</th>
                                                <th className='text-left text-table-heading pt-2 pb-2 pe-2'>Group Name</th>
                                                <th className='text-left text-table-heading pt-2 pb-2 pe-2'>Advertisement</th>
                                                <th className='text-left text-table-heading pt-2 pb-2 pe-2'>Post Name</th>
                                            
                                                <th className='text-left text-table-heading pt-2 pb-2 pe-2'>Actions</th>

                                            </thead>
                                            <tbody>
                                                {
                                                    AllUserGroup?.map((item, index) => {
                                                        const slNo = (currentPage - 1) * selectedPageSize + index + 1;
                                                     
                                                        console.log(selectAdvertisementOption,"selectAdvertisementOption")
                                             const advertisement=selectAdvertisementOption?.find(data=>data?.value==item?.advertisement)
                                             console.log("advertiesmenet",advertisement)
                                             const postName=advertisement?.key?.postAndVacancyConfig?.configurations?.find(data=>data?.posting?._id==item?.postName)
       
  
                                             
                                                        console.log({postName})
                                                        return (
                                                            <tr key={item?._id} className='allTableRow'>
                                                                <td className='text-left fontSizetr'>{slNo} </td>
                                                                <td className='text-left fontSizetr' >{item?.groupName}</td>
                                                                <td className='text-left fontSizetr' >{advertisement?.label}</td>
                                                                <td className='text-left fontSizetr '>{postName?.posting?.postName?.designationName}</td>


                                                                <td className='text-left'>
                                                                    <ButtonGroup>
                                                                        <Button size='sm' onClick={() => { setEditUserGroupModal(true); setSelectedUserGroup(item); }} variant="foreground-alternate" className="btn-icon btn-icon-only">
                                                                            <CsLineIcons icon="edit" />
                                                                        </Button>


                                                                        <Button size='sm' variant="foreground-alternate" className="btn-icon btn-icon-only">
                                                                            <CsLineIcons icon="bin" />
                                                                        </Button>
                                                                    </ButtonGroup>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>

                            )

                        }

                    </Row>

                </Card>
                <Row xs={12} className='d-flex justify-content-center'>
                    <CustomPagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={totalPage} />
                </Row>


          

                <AddGradeModal
                    selectAdvertisementOption={selectAdvertisementOption}
                    allAdminUser={allAdminUser}
                    postAndVacancy={postAndVacancy}
                    OpenAddUserGroupModal={OpenAddUserGroupModal}
                    setOpenUserGroupModal={setOpenUserGroupModal}
                    getAllUserGroup={getAllUserGroup}
                />
                <EditGardePayModal
                    selectAdvertisementOption={selectAdvertisementOption}
                    allAdminUser={allAdminUser}
                    postAndVacancy={postAndVacancy}
                    setEditUserGroupModal={setEditUserGroupModal}
                    editUserGroupModal={editUserGroupModal}
                    selectedUserGroup={selectedUserGroup}
                    getAllUserGroup={getAllUserGroup}

                />
            </>
        )


    )
}

export default UserGroup