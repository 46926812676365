import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { callApi } from 'utils/callApi';
import { CloudHSM } from 'aws-sdk';

function EditUserGroupModal({ postAndVacancy, allAdminUser, selectAdvertisementOption, setEditUserGroupModal, editUserGroupModal, getAllUserGroup, selectedUserGroup }) {
  console.log({ allAdminUser });
  console.log({ postAndVacancy });

  const [groupName, setGroupName] = useState("");
  const [postName, setPostName] = useState('');
  const [groupOfUser, setGroupOfUser] = useState([]);
  const [advertisement, setAdvertisement] = useState("")
  const [errors, setErrors] = useState(null);
  const [postAndVacancyOption, setPostAndVacancyOption] = useState("")


  useEffect(() => {


    console.log(postName)
  }, [postName])
  useEffect(() => {
    if (selectedUserGroup) {
      setGroupName(selectedUserGroup?.groupName)
      // const filterpostName = postAndVacancy?.find((data) => data?.value === selectedUserGroup?.postName)
      // setPostName(filterpostName)
      const filterDataForEssentialQualification = selectedUserGroup?.groupOfUser?.map(id => {
        const option = allAdminUser?.find(opt => opt.value === id);

        return option ? option : { value: id, label: 'Unknown' };
      })
      setGroupOfUser(filterDataForEssentialQualification)
      const filterAdvertisement = selectAdvertisementOption?.find(data => data.value == selectedUserGroup?.advertisement)
      const filteredItem = filterAdvertisement?.key?.postAndVacancyConfig?.configurations?.filter(
        item => item?.posting?._id === selectedUserGroup?.postName
      )[0];
      
      const result = filteredItem ? {
        label: filteredItem?.posting?.postName?.designationName,
        value: filteredItem?.posting?._id
      } : null;
      

      setPostName(result)
      console.log(result, "filterAdvertisement");
      setAdvertisement(filterAdvertisement)
      //  setPostName(selectAdvertisementOption?.find(data=>data?.key?postAndVacancyConfig.configurations))

      setPostAndVacancyOption((selectAdvertisementOption?.key?.postAndVacancyConfig?.configurations?.map(item => ({
        label: item?.posting?.postName?.designationName,
        value: item?.posting?._id
    }))))

    }
  }, [selectedUserGroup])






  useEffect(()=>{

    setPostAndVacancyOption((advertisement?.key?.postAndVacancyConfig?.configurations?.map(item => ({
      label: item?.posting?.postName?.designationName,
      value: item?.posting?._id
  }))))
 
  },[advertisement])

  const validate = () => {
    const error = {};
    if (!groupName?.trim()) {
      error.groupName = "*This field is required";
    }
    if (!postName) {
      error.postName = "*This field is required";
    }
    setErrors(error);
    return error;
  };

  const handleAddGrade = async () => {
    try {
      const error = validate();
      if (Object.keys(error).length > 0) {
        return false;
      }
      let config = {
        method: 'post',
        url: 'master/userGroup/admin/updateUserGroup',
        data: {
          id: selectedUserGroup?._id,
          groupName: groupName,
          postName: postName?.value,
          groupOfUser: groupOfUser?.map(user => user?.value),
          advertisement:advertisement?.value

        }
      };
      const response = await callApi(config);
      if (response?.data?.code === 200) {
        setEditUserGroupModal(false);
        await getAllUserGroup();
        toast.success("User Group Updated Successfully", { autoClose: 2000 });
      } else if (response?.data?.code === 403) {
        toast.error("User Group already exists.", { autoClose: 2000 });
        setEditUserGroupModal(false);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong. Please try again!", { autoClose: 2000 });
    }
  };



  return (
    <div>
      <Modal show={editUserGroupModal} scrollable onHide={() => { setEditUserGroupModal(false); }} size="lg">
        <Modal.Header closeButton className='p-2 m-3 pb-2'>
          {/* <Modal.Title>Edit User Group</Modal.Title> */}
          <h3 className='topModalHead-design'>Edit User Group</h3>
        </Modal.Header>
        {/* <h6 className='text-danger ms-5 mt-2 modalLabelFonts'>All fields with * mark are required!</h6> */}
        <Modal.Body className='pt-1 ps-5'>
          <Row md='12'>
            <Col md='6'>
              <Form.Label className='modalLabelFonts'> Group Name<span className='text-danger mx-1'>*</span></Form.Label>
              <Form.Control
                value={groupName}
                onChange={(e) => { setGroupName(e.target.value); }}
              />
              {errors?.groupName && <p className='text-danger'>{errors?.groupName}</p>}
            </Col>

            <Col md="6">


              <Form.Label className=' modalLabelFonts'>Select Advertisement  <span className='text-danger mx-1'>*</span></Form.Label>

              <Select
                options={selectAdvertisementOption}
                classNamePrefix="react-select"
                value={advertisement}
                onChange={(e) =>{ setAdvertisement(e); setPostName("")}}
              />
              {errors?.advertisement && <p className='text-danger'>{errors?.advertisement}</p>}
            </Col>


          </Row>
          <Row md='12' className='mt-3'>

            <Col md='6'>
              <Form.Label className='modalLabelFonts'>Post Name<span className='text-danger mx-1'>*</span></Form.Label>
              <Select
                classNamePrefix="react-select"
                options={postAndVacancyOption}
                placeholder="Select Post Name"
                value={postName}
                onChange={(e) => setPostName(e)}
              />
              {errors?.postName && <p className='text-danger'>{errors?.postName}</p>}
            </Col>
            <Col md='6'>
              <Form.Label className='modalLabelFonts'>User Group<span className='text-danger mx-1'>*</span></Form.Label>
              <Select
                classNamePrefix="react-select"
                placeholder="Select User Group"
                options={allAdminUser}
                isMulti
                value={groupOfUser}
                onChange={(selectedOptions) => setGroupOfUser(selectedOptions)}
              />
            </Col>




          </Row>
        </Modal.Body>
        <Modal.Footer className='p-2 m-3 pb-2'>
          <Button variant="" onClick={() => { setEditUserGroupModal(false); }} className='ps-4 pe-4 modal-cancel-btn text-black text-uppercase'>
            Close
          </Button>
          <Button onClick={handleAddGrade} className='ps-4 pe-4 modal-submit-btn text-uppercase'>Submit</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EditUserGroupModal;
