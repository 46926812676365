import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { callApi } from 'utils/callApi';

function AddUserGroupModal({ selectAdvertisementOption, postAndVacancy, allAdminUser, setOpenUserGroupModal, OpenAddUserGroupModal, getAllUserGroup }) {
    console.log({ allAdminUser });
    console.log(selectAdvertisementOption)
    console.log({ postAndVacancy });

    const [groupName, setGroupName] = useState("");
    const [postName, setPostName] = useState('');
    const [groupOfUser, setGroupOfUser] = useState([]);
    const [errors, setErrors] = useState(null);
    const [advertisement, setAdvertisement] = useState()
    const [postAndVacancyOption, setPostAndVacancyOption] = useState("")

    const validate = () => {
        const error = {};
        if (!groupName?.trim()) {
            error.groupName = "*This field is required";
        }
        if (!postName) {
            error.postName = "*This field is required";
        }

        if (!advertisement) {
            error.advertisement = "*This field is required"
        }
        if (groupOfUser?.length == 0) {
            error.groupOfUser = "*This field is required";
        }
       
        setErrors(error);
        return error;
    };

    const handleAddGrade = async () => {
        try {
            const error = validate();
            console.log(error,"errors")
            if (Object.keys(error).length > 0) {
                return false;
            }
            let config = {
                method: 'post',
                url: 'master/userGroup/admin/addUserGroup',
                data: {
                    groupName: groupName,
                    advertisement: advertisement?.value,
                    postName: postName?.value,
                    groupOfUser: groupOfUser?.map(user => user?.value),


                }
            };
            const response = await callApi(config);
            if (response?.data?.code === 200) {
                setOpenUserGroupModal(false);
                await getAllUserGroup();
                toast.success("User Group Created Successfully", { autoClose: 2000 });
                resetFields();
            } else if (response?.data?.code === 403) {
                toast.error("User Group already exists.", { autoClose: 2000 });
                setOpenUserGroupModal(false);
                resetFields();
            }
        } catch (error) {
            console.log(error);
            toast.error("Something went wrong. Please try again!", { autoClose: 2000 });
        }
    };

    const resetFields = () => {
        setGroupName("");
        setPostName('');
        setGroupOfUser([]);
        setErrors(null);
      
    };

    useEffect(() => {
        console.log(advertisement?.key?.postAndVacancyConfig?.configurations?.map(item => ({
            label: item?.posting?.postName?.designationName,
            value: item?.posting?._id
        })));



        setPostAndVacancyOption((advertisement?.key?.postAndVacancyConfig?.configurations?.map(item => ({
            label: item?.posting?.postName?.designationName,
            value: item?.posting?._id
        }))))


        setPostName("")


    }, [advertisement])

    
    return (
        <div>
            <Modal show={OpenAddUserGroupModal} onHide={() => { setOpenUserGroupModal(false); resetFields(); }} size="lg">
                <Modal.Header closeButton className='p-2 m-3 pb-2'>
                    {/* <Modal.Title>Add User Group</Modal.Title> */}
                    <h3 className='topModalHead-design'>Add User Group</h3>
                </Modal.Header>
                {/* <h6 className='text-danger ms-5 mt-2 modalLabelFonts'>All fields with * mark are required!</h6> */}
                <Modal.Body className='pt-1 ps-5'>
                    <Row md='12'>
                        <Col md='6'>
                            <Form.Label className='modalLabelFonts'>Group Name<span className='text-danger mx-1'>*</span></Form.Label>
                            <Form.Control
                                value={groupName}
                                onChange={(e) => { setGroupName(e.target.value); }}
                            />
                            {errors?.groupName && <p className='text-danger'>{errors?.groupName}</p>}
                        </Col>
                        <Col md="6">


                            <Form.Label className=' modalLabelFonts'>Select Advertisement  <span className='text-danger mx-1'>*</span></Form.Label>

                            <Select
                                options={selectAdvertisementOption}
                                classNamePrefix="react-select"
                                value={advertisement}
                                onChange={(e) => setAdvertisement(e)}
                            />
                            {errors?.advertisement && <p className='text-danger'>{errors?.advertisement}</p>}
                        </Col>

                    </Row>
                    <Row md='12' className='mt-3'>

                        <Col md='6'>
                            <Form.Label className='modalLabelFonts'>Post Name<span className='text-danger mx-1'>*</span></Form.Label>
                            <Select
                                classNamePrefix="react-select"
                                options={postAndVacancyOption}
                                placeholder="Select Post Name"
                                value={postName}
                                onChange={(e) => setPostName(e)}
                            />
                            {errors?.postName && <p className='text-danger'>{errors?.postName}</p>}
                        </Col>


                        <Col md='6'>
                            {/* <Form.Label>User Group<span className='text-danger mx-1'>*</span></Form.Label> */}
                            <Form.Label className=' modalLabelFonts'>User Group<span className='text-danger mx-1'>*</span></Form.Label>
                            <Select
                                classNamePrefix="react-select"
                                placeholder="Select User Group"
                                options={allAdminUser}
                                isMulti
                                value={groupOfUser}
                                onChange={(selectedOptions) => setGroupOfUser(selectedOptions)}
                            />
                            {errors?.groupOfUser && <p className='text-danger'>{errors?.groupOfUser}</p>}
                        </Col>

                    </Row>




                </Modal.Body>
                <Modal.Footer className='p-2 m-3 pb-2'>
                    <Button variant="" onClick={() => { setOpenUserGroupModal(false); resetFields(); }} className='ps-4 pe-4 modal-cancel-btn text-black text-black'>
                        Close
                    </Button>
                    <Button onClick={handleAddGrade} className='ps-4 pe-4 modal-submit-btn text-uppercase'>Submit</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default AddUserGroupModal;
