/* eslint-disable */
import { Component, lazy } from 'react';
import { USER_ROLE } from 'constants.js';
import { DEFAULT_PATHS } from 'config.js';
import { components } from 'react-select/dist/react-select.cjs.prod';
import UserGroup from 'views/masters/userGroup/UserGroup';
// import Appointment from 'views/Appointment/appointment';



const PostVacancy = {
  // postVacancy: lazy(() => import('views/PostVacancy/EditableRows')),
  applications: lazy(() => import('views/Applications/Applications')),
  calendar: lazy(() => import('views/Applications/Calendar')),
  applicationDetails: lazy(() => import('views/Applications/ApplicationDetailsModal')),
  // documentVerification: lazy(() => { import('views/Applications/DocumentVarification') })
  // applicationDetails: lazy(() => import('views/profile/ProfileStandard')),
  // interview: lazy(() => import('views/Interview/EditableRows')),
  // schedule: lazy(() => import('views/Shadule/Sortable')),
  // applicationDetails: lazy(() => import('views//EditableRows')),
  // allIngredients: lazy(() => import('views/Inventory/allIngredients')),
  applicantScoringSheet: lazy(() => import('views/Applications/components/ApplicantScoringScreen'))
};


 


const Advertisement = {
  advertisements: lazy(() => import('views/Advertisements/advertisements')),
  forms: lazy(() => import('views/Forms/ApplicationForms')),
  ScreeningCriteria: lazy(() => import('views/ScreeningCriteria/ScreeningCriteria')),
  Task: lazy(() => import('views/ScreeningCriteria/Tasks')),
};



const Roles = {
  index: lazy(() => import('views/roles/home')),
  allRoles: lazy(() => import('views/roles/roles/allRoles')),
  setRoles: lazy(() => import('views/roles/roles/SetRoles')),
  setPages: lazy(() => import('views/roles/roles/SetPages')),
  users: lazy(() => import('views/Users/Users')),
  UserGroup: lazy(() => import('views/masters/userGroup/UserGroup'))

};

const Masters = {
  department: lazy(() => import('views/masters/Department/Departments')),
  postOrVacancy: lazy(() => import('views/masters/PostOrVacancy/PostOrVacancies')),



}
const MasterData = {
  payLevel: lazy(() => import('views/masters/allmasters/paylevelmaster/paylevel')),
  paycell: lazy(() => import('views/masters/allmasters/paycell/paycell')),
  grade: lazy(() => import('views/masters/allmasters/grade/grade')),
  designation: lazy(() => import('views/masters/allmasters/Designation/designation')),
  catagories: lazy(() => import('views/masters/allmasters/Category.js/catagories')),
  qualification: lazy(() => import('views/masters/allmasters/qualification/qualification')),
 pwbdDisability:lazy(()=>import('views/masters/allmasters/pwbdDisabilityTypeMaster/pwbddisabilityMaster'))


}
const Interview = {
  calander: lazy(() => import('views/Interview/Calendar')),
  selectedForInterview: lazy(() => import('views/Interview/selectedApplicationForInterview')),
  advtScheduledForInterview: lazy(() => import('views/Interview/advertisemntDataForInterview')),
  applicationDetails: lazy(() => import('views/Interview/ApplicationDetailsByAdvt')),

}

const dashboards = {
  index: lazy(() => import('views/dashboards/Dashboards')),
  default: lazy(() => import('views/dashboards/DashboardsDefault')),
  visual: lazy(() => import('views/dashboards/DashboardsVisual')),
  analytic: lazy(() => import('views/dashboards/DashboardsAnalytic')),
};


const Mis = {
  candidateDetails: lazy(() => import('views/MIS/candidateDetails')),
  vacancyMisSheet: lazy(() => import('views/MIS/vacancywiseMisSheet')),
  AdvertisementSummary: lazy(() => import('views/MIS/AdvertisementSummary')),
  InterviewCommittee: lazy(() => import('views/MIS/InterviewCommitteeReportMis'))
}



const ApplicationDetailsById = {
  applicationdetailsadvid: lazy(() => import('views/Applications/ApplicationDetailsByAdvt')),
  documentVerification: lazy(() => import('views/Applications/DocumentVarification'))
}

const applicationforScrutiny = {
  allApplication: lazy(() => import('views/ApplicationsforScrutiny/Applications')),
  applicationdetailsadvidScrutiny: lazy(() => import('views/ApplicationsforScrutiny/ApplicationDetailsByAdvt')),
  applicantScoringSheetScrutiny: lazy(() => import('views/ApplicationsforScrutiny/components/ApplicantScoringScreen'))
}

const appRoot = DEFAULT_PATHS.APP.endsWith('/') ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length) : DEFAULT_PATHS.APP;

const routesAndMenuItems = {
  mainMenuItems: [
    {
      path: DEFAULT_PATHS.APP,
      exact: true,
      redirect: true,
      to: `${appRoot}/dashboards/default`,
      roles: ["SuperAdmin"]
    },
    {
      path: `${appRoot}/dashboards`,
      component: dashboards.index,
      label: 'menu.dashboard',
      roles: ["SuperAdmin"],
      component: dashboards.analytic,
      icon: 'home',

    },

    {
      path: `${appRoot}/advertisements`,
      label: 'Advertisement',
      roles: ["SuperAdmin", "Admin"],
      icon: 'news',
      subs: [
        { path: '/Forms', label: 'Dynamic Form Builder', component: Advertisement.forms },
        { path: '/Advertisements', label: 'Manage Advertisement', component: Advertisement.advertisements },
      ],
    },

    {
      path: `${appRoot}/Applications`,
      label: 'Interview Management',
      roles: ["SuperAdmin", "Admin"],
      icon: 'online-class',
      component: PostVacancy.applications,
      subs: [
        ,
        // { path: '/applications', label: 'Received Applications ', component: PostVacancy.applications },
        { path: '/calander', component: PostVacancy.calendar },
        { path: '/ApplicationsDetails', component: PostVacancy.applicationDetails },
        { path: '/selectedForInterview', component: Interview.selectedForInterview },

        { path: '/ApplicationsDetail', component: Interview.applicationDetails },
        { path: '/applicantScoringSheet', component: PostVacancy.applicantScoringSheet },


      ],
    },

    {
      path: `${appRoot}/ApplicationsforScrutiny`,
      label: 'Interview Management',
      roles: ["Scrutiny Committee"],
      icon: 'online-class',
      component: applicationforScrutiny.allApplication,
      subs: [
        // { path: '/calander', component: PostVacancy.calendar },
        // { path: '/ApplicationsDetails', component: PostVacancy.applicationDetails },
        // { path: '/selectedForInterview', component: Interview.selectedForInterview },
        // { path: '/ApplicationsDetail', component: Interview.applicationDetails },
        { path: '/applicantScoringSheetScrutiny', component: applicationforScrutiny.applicantScoringSheetScrutiny },
      ],
    },
    {
      path: `${appRoot}/applicationdetailsbyadvtscrutiny`,
      component: applicationforScrutiny.applicationdetailsadvidScrutiny,
      roles: ["Scrutiny Committee"],
    },
    {
      path: `${appRoot}/appointment-management`,
      label: 'Appointment Management',
      roles: ["SuperAdmin", "Admin"],
      icon: 'calendar',
      component: Interview.advtScheduledForInterview,
      subs: [
        { path: '/appointment-managementDetails', component: Interview.applicationDetails },



      ],

    },

    {
      path: `${appRoot}/Applications/applicationdetailsbyadvt`,

      component: ApplicationDetailsById.applicationdetailsadvid,
      roles: ["SuperAdmin", "Admin"],


    },
    {
      path: `${appRoot}/documentverification`,
      component: ApplicationDetailsById.documentVerification,
      roles: ["SuperAdmin", "Admin"],

    },
    {
      path: `${appRoot}/masters`,
      component: Masters.index,
      label: 'Master Data',
      roles: ["SuperAdmin", "Admin"],
      icon: 'file-chart',
      redirect: true,
      subs: [
        {
          path: '/allmasters',
          label: 'Masters',
          roles: ["SuperAdmin"],
          subs: [
            { path: '/paylevel', label: 'Pay Level', component: MasterData.payLevel },
            { path: '/paycell', label: 'Pay Cell', component: MasterData.paycell },
            { path: "/qualification", label: "Qualification", component: MasterData.qualification },
            { path: '/designation', label: "Designation", component: MasterData.designation },
            { path: '/grade', label: "Grade", component: MasterData.grade },
            { path: '/departments', label: 'Cadre', component: Masters.department },
            { path: '/catagories', label: "Age Matrix", component: MasterData.catagories },
            {path:"/pwbdDisability" ,label:"PWBD Disability", component:MasterData.pwbdDisability}

          ]

        },
        { roles: ["SuperAdmin", "Admin"], path: '/postOrVacancies', label: 'Vacancies', component: Masters.postOrVacancy },
        {
          path: '/rolesAndUsers',
          label: 'Role & Users',
          roles: ["SuperAdmin"],
          subs: [
            { path: '/roles/all-roles', label: 'Roles', component: Roles.allRoles },
            { path: '/roles/all-users', label: 'Users', component: Roles.users },
            { path: '/userGroup', label: "UserGroup", component: Roles.UserGroup }
          ],
        },
        {
          path: '/mis',
          label: 'MIS',
          roles: ["SuperAdmin", "Admin"],
          subs: [
            { path: '/mis/advertisementSummary', label: "Advertisement Summary", component: Mis.AdvertisementSummary },
            { path: '/mis/vacancyDetails', label: "Vacancy Details", component: Mis.vacancyMisSheet },
            { path: '/mis/candidateDetails', label: 'Candidate Details', component: Mis.candidateDetails },
            { path: '/mis/interviewCommittee', label: 'Interview Committee', component: Mis.InterviewCommittee },
          ],
        },
      ],
    },
  ],

  sidebarItems: [
    { path: '#connections', label: 'menu.connections', icon: 'diagram-1', hideInRoute: true },
    { path: '#bookmarks', label: 'menu.bookmarks', icon: 'bookmark', hideInRoute: true },
    { path: '#requests', label: 'menu.requests', icon: 'diagram-2', hideInRoute: true },
    {
      path: '#account',
      label: 'menu.account',
      icon: 'user',
      hideInRoute: true,
      subs: [
        { path: '/settings', label: 'menu.settings', icon: 'gear', hideInRoute: true },
        { path: '/password', label: 'menu.password', icon: 'lock-off', hideInRoute: true },
        { path: '/devices', label: 'menu.devices', icon: 'mobile', hideInRoute: true },
      ],
    },
    {
      path: '#notifications',
      label: 'menu.notifications',
      icon: 'notification',
      hideInRoute: true,
      subs: [
        { path: '/email', label: 'menu.email', icon: 'email', hideInRoute: true },
        { path: '/sms', label: 'menu.sms', icon: 'message', hideInRoute: true },
      ],
    },
    {
      path: '#downloads',
      label: 'menu.downloads',
      icon: 'download',
      hideInRoute: true,
      subs: [
        { path: '/documents', label: 'menu.documents', icon: 'file-text', hideInRoute: true },
        { path: '/images', label: 'menu.images', icon: 'file-image', hideInRoute: true },
        { path: '/videos', label: 'menu.videos', icon: 'file-video', hideInRoute: true },
      ],
    },
  ],
};
export default routesAndMenuItems;
