import { deleteCurrentUser } from './helpers';
import { apiSecret, apiUrl, getToken } from '../constants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var axios = require('axios');
// api call data required method,data,url as its parameter
export const callApi = async (apiCallData) => {
  // toast(" Your Session is expired ", { className: "danger", autoClose: 2000 });
  var data = { ...apiCallData?.data, "api_key": `${apiSecret}` }
  const token = await getToken();
  let config = {
    method: apiCallData.method,
    url: `${apiUrl}${apiCallData.url}`,
    // url: `https://backend.dev.bemc.teceads.co.in/api/v1/ramalingampark/event/getEvent`,
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*"
    },
    withCredentials: true,
    data: data
  }
  console.log({config})
  axios.default.defaults.withCredentials = true;
  let response = await axios(config)
  console.log(response)
  if (response.data.code === 401) {

    toast("Your session is expired", { 
      className: "danger", 
      autoClose: 2000, 
      onClose: () => {
        deleteCurrentUser();
        window.location.assign('/login');
      }
    });
    
  } else {
    return (response)
  }

}

