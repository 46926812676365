import { SERVICE_URL } from 'config';
import api from '../api';

let guid = 0;
const createId = () => {
  guid += 1;
  return String(guid);
};

let tasksData = [
  {
    "id": createId(),
    "status": "Active",
    "title": "Screen for 8+ Years of Experience",
    "detail": "Filter candidates to ensure they have at least 8 years of relevant job experience in the field.",
    "tags": [
      {
        "title": "Urgent",
        "bg": "outline-danger"
      },
      {
        "title": "Project",
        "bg": "outline-primary"
      }
    ],
    "deleted": false
  },
  {
    "id": createId(),
    "status": "Active",
    "title": "Verify Postgraduate Qualifications",
    "detail": "Check that all applicants possess a minimum of a postgraduate degree in a relevant discipline.",
    "tags": [
      {
        "title": "Urgent",
        "bg": "outline-danger"
      },
      {
        "title": "List",
        "bg": "outline-success"
      }
    ],
    "deleted": false
  },
  {
    "id": createId(),
    "status": "Active",
    "title": "Assess Leadership Experience",
    "detail": "Evaluate candidates for proven leadership experience and roles in previous positions.",
    "tags": [
      {
        "title": "Urgent",
        "bg": "outline-danger"
      },
      {
        "title": "Personal",
        "bg": "outline-warning"
      }
    ],
    "deleted": false
  },
  {
    "id": createId(),
    "status": "Active",
    "title": "Check for Industry Certifications",
    "detail": "Ensure candidates hold up-to-date industry certifications relevant to the job function.",
    "tags": [
      {
        "title": "List",
        "bg": "outline-success"
      },
      {
        "title": "Personal",
        "bg": "outline-warning"
      }
    ],
    "deleted": false
  },
  {
    "id": createId(),
    "status": "Active",
    "title": "Review Project Management Skills",
    "detail": "Screen for candidates with demonstrated project management skills and a track record of successful project delivery.",
    "tags": [
      {
        "title": "Project",
        "bg": "outline-primary"
      }
    ],
    "deleted": false
  },
  {
    "id": createId(),
    "status": "Done",
    "title": "Evaluate Technical Proficiency",
    "detail": "Assess candidates on technical skills and proficiency in tools and technologies relevant to the job.",
    "tags": [
      {
        "title": "Project",
        "bg": "outline-primary"
      }
    ],
    "deleted": false
  },
  {
    "id": createId(),
    "status": "Done",
    "title": "Language Proficiency Check",
    "detail": "Confirm that candidates meet the language proficiency requirements for the role, including fluency in specific languages.",
    "tags": [
      {
        "title": "Project",
        "bg": "outline-primary"
      }
    ],
    "deleted": false
  },
  {
    "id": createId(),
    "status": "Active",
    "title": "Confirm Eligibility to Work",
    "detail": "Verify candidates have the legal right to work in the job location without requiring visa sponsorship.",
    "tags": [
      {
        "title": "Personal",
        "bg": "outline-warning"
      }
    ],
    "deleted": false
  },
  {
    "id": createId(),
    "status": "Active",
    "title": "Cultural Fit Assessment",
    "detail": "Screen candidates for alignment with company culture and values through targeted questions and assessments.",
    "tags": [],
    "deleted": false
  },
  {
    "id": createId(),
    "status": "Active",
    "title": "Soft Skills Analysis",
    "detail": "Evaluate candidates' communication, teamwork, and problem-solving skills to ensure they match the role requirements.",
    "tags": [
      {
        "title": "Lists",
        "bg": "outline-success"
      },
      {
        "title": "Personal",
        "bg": "outline-warning"
      }
    ],
    "deleted": false
  }
]

api.onGet(`${SERVICE_URL}/apps/tasks`).reply(200, tasksData);

api.onPut(`${SERVICE_URL}/apps/tasks`).reply((config) => {
  const requestData = JSON.parse(config.data);
  const { item } = requestData;
  if (tasksData.find((x) => x.id === item.id)) {
    tasksData = tasksData.map((x) => (x.id === item.id ? item : x));
    // Update item
    return [200, tasksData];
  }
  tasksData.push({ ...item, id: createId() });
  return [200, tasksData];
});

api.onPost(`${SERVICE_URL}/apps/tasks`).reply((config) => {
  const requestData = JSON.parse(config.data);
  const { item } = requestData;

  // Add item
  tasksData = [{ ...item, id: createId() }, ...tasksData];
  return [200, tasksData];
});

api.onDelete(`${SERVICE_URL}/apps/tasks`).reply((config) => {
  const { id } = config;
  // Delete item
  tasksData = [...tasksData.filter((x) => id !== x.id)];
  return [200, tasksData];
});
