import { camelCase } from "change-case";

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem('data') != null
        ? JSON.parse(localStorage.getItem('data'))
        : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null;
  }
  return user;
};


export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('data', JSON.stringify(user));

    } else {
      localStorage.removeItem('data');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

export const deleteCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.removeItem('data');
    } else {
      localStorage.removeItem('data');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

export function convertKeysToCamelCase(obj) {
  const newObj = {};
  for (const key of Object.keys(obj)) {
    const camelCaseKey = camelCase(key);
    newObj[camelCaseKey] = obj[key];
  }
  return newObj;
}
